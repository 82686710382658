import axios from 'axios';
import { toast } from 'react-toastify';

import { signOut } from '../api/user';
import { queryClient } from './queryClient.config';

import i18n from '../integrations/i18n';

const API_URL = process.env.REACT_APP_API_URL;

delete axios.defaults.headers.common;

export const ERROR_JWT_TOKEN_NOT_FOUND = 'ERROR_JWT_TOKEN_NOT_FOUND';

const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
    patch: {
      'Content-Type': 'application/json',
    },
    put: {
      'Content-Type': 'application/json',
    },
  },
});

client.interceptors.request.use(
  function (config) {
    if (config.url.includes('login') || config.url.includes('logout')) {
      return config;
    }

    // API request to `edge/` will be intecepted and cookies will not be sent
    if (config.url.includes('edge')) {
      config.withCredentials = false;
    }

    const token = localStorage.getItem('nuvo-token');

    if (
      !token &&
      !config.url.includes('check-auth') &&
      // this is a hack to avoid logout when loging in via app /3001 for dev purposes
      process.env.NODE_ENV !== 'development' &&
      process.env.NODE_ENV !== 'test'
    ) {
      throw new axios.Cancel(ERROR_JWT_TOKEN_NOT_FOUND);
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  function (response) {
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error?.response?.status === 401 &&
      !error.config.url.includes('login')
    ) {
      await queryClient.fetchQuery('signout', () => signOut());

      toast.info(i18n.t('internalui-session-expired'), {
        toastId: 'sessionExpired',
      });

      queryClient.invalidateQueries('check-auth');
    }

    return Promise.reject(error);
  }
);

client.gql = (params, config = {}) =>
  client.post('/graphql', params, config).then(r => {
    if (r.data?.errors) {
      throw new Error(r);
    }

    return r;
  });

export { client };
