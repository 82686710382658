import { CTAButton, Modal } from '@nuvocargo/nuvo-styleguide';
import { TabsContainer, TrackAndTraceTab } from './Tabs';
import { useTranslation } from 'react-i18next';
import { useUser, useFeatures } from 'context';
import { isCustomerSuccess } from 'core/utils/roles';
import { Link } from 'react-router-dom';
import { buildShipmentDetailsLink, shipmentDetailsViewTypes } from '../utils';
import { theme } from 'styles';
import { theme as colorTheme } from '@nuvocargo/nuvo-styleguide/lib/theme';

const Sidebar = ({
  children,
  closeShipmentDetailsView,
  shipmentDetailsView,
  shipmentId,
  isExceptionSidebar = false,
}) => {
  const { t } = useTranslation();
  const { currentRole } = useUser();
  const { routesIsEnabled } = useFeatures();

  return (
    <Modal
      isOpen={!!shipmentDetailsView && !!shipmentId}
      closeModal={closeShipmentDetailsView}
      variant="rightSidebar"
      styles={{
        sidebar: {
          width: '764px',
          backgroundColor: `${theme.colors.cream}`,
        },
        close_button: {
          right: '32px',
          top: '14px',
          backgroundColor: `${colorTheme.colorWithAlpha('darkGrey/10')}`,
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          color: `${theme.colors.forest}`,
        },
      }}>
      <TabsContainer>
        {isExceptionSidebar ? (
          <TabsContainer>
            <CTAButton
              isLink
              as={Link}
              to={buildShipmentDetailsLink({}, 'overview', { shipmentId })}
              colorType="secondary"
              size="medium"
              icon="west"
              styles={{
                secondary: {
                  backgroundColor: `${colorTheme.colorWithAlpha(
                    'darkGrey/10'
                  )}`,
                  margin: '12px 0 12px 32px',
                },
              }}
            />
          </TabsContainer>
        ) : (
          <>
            <TrackAndTraceTab
              viewName="overview"
              name={t('overview-navigation-tab')}
              isActive={
                shipmentDetailsView === shipmentDetailsViewTypes.OVERVIEW
              }
            />

            {routesIsEnabled && (
              <TrackAndTraceTab
                viewName="route"
                name={t('routes-navigation-tab')}
                isActive={
                  shipmentDetailsView === shipmentDetailsViewTypes.ROUTE
                }
              />
            )}

            {isCustomerSuccess(currentRole) && (
              <TrackAndTraceTab
                viewName="configuration"
                name={t('configuration-navigation-tab')}
                isActive={
                  shipmentDetailsView === shipmentDetailsViewTypes.CONFIGURATION
                }
              />
            )}
          </>
        )}
      </TabsContainer>

      {children}
    </Modal>
  );
};

export default Sidebar;
