import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import * as paths from 'routes/paths';
import NextFormButton from '../../components/NextFormButton';
import { Formik, Form } from 'formik';
import useServiceability from './useServiceability';
import { ACTIONS_TYPES } from '../../store/reducer';
import { ROUTE_SERVICEABLE_ENUM } from 'core/utils/routeUtils';
import {
  pricingRequestStyles,
  ResizeContainerDiv,
} from 'screens/pricing-request/pricingRequest.styles';

const Serviceability = ({ state, dispatch }) => {
  const { t } = useTranslation();
  const { id: requestId } = useParams();
  const history = useHistory();
  const { columns, mutateUpdateRoute } = useServiceability();

  if (!columns) {
    return <></>;
  }

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">{t('serviceability-title')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Text color="steel">{t('serviceability-subtitle')}</Text>
      </Stack>
      <Formik
        enableReinitialize
        initialValues={{
          routes: state.routes || [],
        }}
        onSubmit={({ routes }) => {
          const atLeastOneServiceableRoute = routes.some(
            ({ serviceable }) =>
              serviceable.value === ROUTE_SERVICEABLE_ENUM.SERVICEABLE
          );
          mutateUpdateRoute.mutate({ requestIdentifier: requestId });
          dispatch({
            type: ACTIONS_TYPES.SET_ROUTES,
            payload: routes,
          });
          if (atLeastOneServiceableRoute) {
            history.push(
              generatePath(paths.app.pricingRequestsLegCosts, {
                id: requestId,
              })
            );
          } else {
            history.push(
              generatePath(paths.app.pricingRequestsReviewAndPublish, {
                id: requestId,
              })
            );
          }
        }}>
        <Form>
          <InputTable
            hideActions
            name="routes"
            columnIdentifier="id"
            columns={columns}
          />

          <div style={pricingRequestStyles.nextButton}>
            <NextFormButton />
          </div>
        </Form>
      </Formik>
    </ResizeContainerDiv>
  );
};

export default Serviceability;
