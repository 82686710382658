import { useMemo } from 'react';
import { app } from 'routes/paths';
import { useTranslation } from 'react-i18next';
import {
  InputCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { ACTIONS_TYPES } from '../../store/reducer';
import { toCurrency } from 'core/utils/inputToCurrency';
import { updateLegQuoteDataFromUserPremiumRate } from '../../store/utils';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';

const DirectServicePremiumColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        // @Todo distance change to miles directly
        Header: t('general-distance-text-label'),
        accessor: 'distance',
        Cell: ({ value, ...rest }) => {
          const distance = (value / 1609.344)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return <TextCell fontColor="green" text={distance} {...rest} />;
        },
      },
      {
        Header: t('general-direct-premium-text-label'),
        accessor: 'directPremiumCost',
        Cell: ({ value, onChange, ...rest }) => {
          return (
            <InputCell
              type="number"
              pattern="\d*"
              value={value}
              onChange={e => onChange(toCurrency(e.target.value))}
              leftComponent={<Icon name="attachMoney" />}
              {...rest}
            />
          );
        },
      },
    ],
    []
  );
  return columns;
};

export const useDirectServicePremiumTable = ({ dispatch }) => {
  const columns = DirectServicePremiumColumns();
  const { id: requestId } = useParams();
  const history = useHistory();

  const onSubmit = ({ legQuotes }) => {
    dispatch({
      type: ACTIONS_TYPES.SET_LEG_QUOTES_FROM_USER,
      payload: updateLegQuoteDataFromUserPremiumRate(legQuotes),
    });
    history.push(
      generatePath(app.pricingRequestsLegCostsDetails, {
        id: requestId,
      })
    );
  };

  return {
    columns,
    onSubmit,
  };
};
