import { useReducer } from 'react';
import { ACTIONS_TYPES, initialState, reducer } from './store/reducer';
import { useQuery } from 'react-query';
import { getRoutesByRequestIdentifier } from '../../core/api';
import { transformGetRoutesByRequestIdentifier } from './store/utils';
import { useTranslation } from 'react-i18next';
import * as nuvoPaths from 'routes/paths';

const UsePricingRequest = ({ requestId }) => {
  const { t } = useTranslation();
  const paths = nuvoPaths.app;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { isLoading } = useQuery(
    ['pricingRequest', requestId],
    () => getRoutesByRequestIdentifier({ requestIdentifier: requestId }),
    {
      onSuccess: data => {
        dispatch({
          type: ACTIONS_TYPES.SET_REQUEST_DETAILS,
          payload: data.data.routes.edges[0].node,
        });
        dispatch({
          type: ACTIONS_TYPES.SET_ROUTES,
          payload: transformGetRoutesByRequestIdentifier(data, t),
        });
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    paths,
    state,
    isLoading,
    dispatch,
  };
};

export default UsePricingRequest;
