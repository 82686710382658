import * as FullStory from '@fullstory/browser';
import { client } from 'core/utils/axios.config';
import { useQuery, useMutation, useQueryClient } from 'react-query';

export const getUsers = ({
  page,
  perPage,
  temporaryInfinitePageSizeHack,
  email,
  roleSlug,
}) =>
  client
    .get(`/v0/users`, {
      params: {
        temporaryInfinitePageSizeHack,
        fields: 'id,displayName,email,firstName,lastName',
        'filter[internal_roles]': roleSlug,
        'filter[email]': email,
        'page[number]': page,
        'page[size]': perPage,
        include: 'phone_numbers',
      },
    })
    .then(response => response.data);

// TODO: Update this to use the real API once endpoint is implemented
const getUser = ({ id }) =>
  client.get(`/v0/users/${id}`, {
    params: {
      include: 'phone_numbers',
    },
  });

export const useUserRequest = id =>
  useQuery(['user', id], () => getUser({ id }).then(({ data }) => data));

export const useUsersRequest = (
  {
    page = 1,
    perPage = 20,
    temporaryInfinitePageSizeHack = false,
    email = '',
    roleSlug = '',
  },
  queryOptions
) =>
  useQuery(
    ['users', page, perPage, email, roleSlug, temporaryInfinitePageSizeHack],
    () =>
      getUsers({
        page,
        perPage,
        temporaryInfinitePageSizeHack,
        email,
        roleSlug,
      }),
    queryOptions
  );

const updateUser = ({ id, body }) => client.patch(`/v0/users/${id}`, body);

export const useUpdateUser = (id, onSuccessCallback) => {
  const queryClient = useQueryClient();

  return useMutation(body => updateUser({ id, body }), {
    onSuccess: updatedUser => {
      onSuccessCallback?.(updatedUser);

      FullStory.event('Updated user data', {
        userId: id,
        updateUser,
      });

      return queryClient.invalidateQueries(['users']);
    },
  });
};
