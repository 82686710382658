import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import { useField } from 'formik';
import { ErrorMessage } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const LegCostDetailsTable = ({ columns, name = 'legQuotes' }) => {
  const { 1: meta } = useField({ name });
  const { t } = useTranslation();

  return (
    <ErrorMessage
      error={meta?.error?.length && t('leg-costs-form-required-fields')}>
      <InputTable
        hideActions
        name={name}
        columnIdentifier="id"
        columns={columns}
      />
    </ErrorMessage>
  );
};

export default LegCostDetailsTable;
