import { useEffect } from 'react';

import { useFormikContext } from 'formik';

const ScrollToElement = () => {
  const { isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid || errors.length <= 0) return;

    const fieldName = Object.keys(errors)[0];
    const element = document.querySelector(`input[name='${fieldName}']`);
    if (!element) return;

    element.parentElement.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'start',
    });
  });

  return null;
};

export default ScrollToElement;
