import { useEffect, useMemo } from 'react';
import { useUsersRequest } from 'core/api/usersRequest';
import { useQueryParamsState, useQueryParams } from 'core/hooks';

export const useUsers = () => {
  const qp = useQueryParams();
  const [page = 1, setPage] = useQueryParamsState('page');
  const [email = '', setEmail] = useQueryParamsState('email');
  const [userId, setUserId] = useQueryParamsState('updateUser');
  const { data, isLoading, error } = useUsersRequest({
    page,
    email,
  });

  const { users, usersHt } = useMemo(() => {
    const records = data?.data?.records;

    if (records) {
      return {
        users: records,
        usersHt: records.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        ),
      };
    }

    return {
      users: [],
      usersHt: {},
    };
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [email]);

  return {
    email,
    error,
    isLoading,
    page,
    qp,
    setEmail,
    setUserId,
    totalPages: data?.data?.metadata?.totalPages ?? 1,
    userId,
    users,
    usersHt,
  };
};
