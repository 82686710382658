import { client } from 'core/utils/axios.config';

export const signIn = session =>
  client
    .post('/login', {
      session,
    })
    .then(response => {
      localStorage.setItem('nuvo-token', response.data.authToken);

      return response.data;
    })
    .catch(error => Promise.reject(error?.response || error));

export const checkAuth = token =>
  client
    .get('/check-auth', {
      headers: token && {
        'X-NUVOCARGO-MAGIC-LINK-TOKEN': token,
      },
    })
    .then(response => response?.data);

export const sendMagicLink = data => client.post('/magic-link', data);

export const signOut = () =>
  client.get('logout').finally(() => {
    localStorage.removeItem('nuvo-token');
  });

export const forgotPassword = data => client.post('/password/forgot', data);

export const resetPassword = data => client.post('/password/reset', data);

export const verifyToken = data => client.post('/password/token', data);

export const validateUser = data => client.post('/onboarding/validate', data);

export const updateLanguage = lng =>
  client.put('/users/language', { language: lng });
