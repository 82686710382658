import * as FullStory from '@fullstory/browser';
import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import Calendar from '@nuvocargo/nuvo-styleguide/Calendar';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { validationSchema } from './data';
import { isDateTimeInOrder } from './utils';
import { getUsedLanguage } from 'core/utils';
import { useUpdateShipmentEventByQueryParam } from 'core/api';
import { useQueryParams } from 'core/hooks';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { buildShipmentDetailsLink } from 'screens/track-and-trace/utils';
import { useShipmentsSidebar } from 'screens/track-and-trace/hooks';
import { theme } from 'styles';
import { useFeatures } from 'context';

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  gap: '32px',
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 32px;
`;

export default function EventDateTimeInput({
  currentEvent = {},
  prevEventDateTime,
}) {
  const { shipmentEventsIssuesEnabled } = useFeatures();
  const { t, i18n } = useTranslation();
  const { shipmentId } = useQueryParams();
  const { qp } = useShipmentsSidebar();

  const updateShipmentEventMutation = useUpdateShipmentEventByQueryParam({
    onSuccess: ({ data }) => {
      FullStory.event('Added Actual Date to Shipment Event', {
        ...data[0],
        shipmentId,
      });
    },
  });

  /**
   * TODO: Replace notifyCustomer from currentEvent to notification settings
   * once the feature is completed
   */
  const { id, notifyCustomer } = currentEvent || {};
  const submit = ({ actualDatetime }) => {
    updateShipmentEventMutation.mutate([
      {
        id,
        actualDatetime: actualDatetime.toISOString(),
        sendNotification: notifyCustomer,
      },
    ]);
  };
  const validateDate = value => {
    // When no prev event date time should return true
    if (!prevEventDateTime) {
      return true;
    }
    // If this returns false, it will trigger a validation error.
    return isDateTimeInOrder(prevEventDateTime, value);
  };
  return (
    <Formik
      initialValues={{
        actualDatetime: (() => {
          const initialDate = new Date();
          initialDate.setMinutes(0, 0, 0);
          return initialDate;
        })(),
      }}
      enableReinitialize
      validationSchema={validationSchema(t, validateDate)}
      validateOnMount
      onSubmit={submit}>
      {({ setFieldValue, isValid, errors, values: { actualDatetime } }) => {
        return (
          <Form style={formStyles}>
            <Calendar
              dateFormat="EEEE d MMM - HH:mm"
              disabled={updateShipmentEventMutation.isLoading}
              placeholder={t('choose_date')}
              error={errors.actualDatetime}
              isReadOnly={false}
              maxDate={new Date()}
              // FIXME: Allows to choose up to one year ago- product is still figuring out better requirement.
              minDate={new Date(new Date().getFullYear())}
              id="actualDatetime"
              name="actualDatetime"
              onChange={setFieldValue}
              showTimeSelect
              timeCaption="Time"
              timeFormat="HH:mm"
              timeIntervals={15}
              value={actualDatetime}
              locale={getUsedLanguage(i18n?.language)}
              label={t('confirmation-date')}
              styles={{ root: { width: '100%' } }}
            />

            <ButtonWrapper>
              {shipmentEventsIssuesEnabled && (
                <>
                  <CTAButton
                    isLink
                    as={Link}
                    to={buildShipmentDetailsLink(qp, 'exception', {
                      shipmentId,
                    })}
                    id="add-exception-button"
                    disabled={updateShipmentEventMutation.isLoading || !isValid}
                    colorType="secondary"
                    styles={{
                      secondary: {
                        backgroundColor: `${theme.colors.softGrey}`,
                        color: `${theme.colors.forest}`,
                      },
                    }}>
                    {t('exception')}
                  </CTAButton>
                </>
              )}
              <CTAButton
                id="actual-datetime-mark-complete"
                disabled={updateShipmentEventMutation.isLoading || !isValid}
                style={{ marginLeft: '12px' }}
                type="submit">
                {t('mark-complete')}
              </CTAButton>
            </ButtonWrapper>
          </Form>
        );
      }}
    </Formik>
  );
}
