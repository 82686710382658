/* eslint-disable react/display-name */
import { app } from 'routes';
import { useMemo } from 'react';
import { Text, Eyebrow, Icon, Link } from '@nuvocargo/nuvo-styleguide';
import { LinkCell } from 'components/common/app';
import { stringify } from 'qs';

const useCreateColumns = ({ t, page, email, setUserId, qp }) =>
  useMemo(
    () => [
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-email-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'email',
        Cell: row => <Text>{row.cell.value}</Text>,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-name-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'id',
        Cell: row => {
          const { firstName, lastName } = row.row.original;
          const name = `${firstName}${firstName ? ' ' : ''}${lastName}`;

          return <Text>{name}</Text>;
        },
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-company-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'company.name',
        Cell: row => <Text>{row.cell.value}</Text>,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-phone-numbers-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'phoneNumbers',
        Cell: row => {
          const numbers = row.cell.value || [];

          return (
            <Text>
              {numbers
                .sort((a, b) =>
                  t(`phone-type-${a.type}`).localeCompare(
                    t(`phone-type-${b.type}`)
                  )
                )
                .reduce(
                  (acc, curr, i) => (
                    <>
                      {acc}
                      <Text style={{ fontWeight: 'bold' }}>
                        {t(`phone-type-${curr.type}`)}:
                      </Text>{' '}
                      <Text>{curr.number}</Text>
                      {i < numbers.length - 1 ? ', ' : ''}
                    </>
                  ),
                  <></>
                )}
            </Text>
          );
        },
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-impersonate-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        id: 'users-table-impersonate-column',
        accessor: 'id',
        Cell: row => (
          <Link
            href={`${process.env.REACT_APP_API_URL}/admin/${row.cell.value}/select`}>
            {t('users-table-impersonate-link')}
          </Link>
        ),
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('users-table-edit-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        id: 'edit-user',
        accessor: 'id',
        Cell: row => (
          <LinkCell
            className="Users__link-cell"
            to={{
              pathname: app.users,
              search: stringify(
                {
                  ...qp,
                  updateUser: row.cell.value,
                },
                { encode: false }
              ),
            }}>
            <Icon name="keyboardArrowRight" color="forest" size="medium" />
          </LinkCell>
        ),
        isLink: true,
        shrinkToContent: true,
      },
    ],
    [t, page, email, setUserId, qp]
  );

export default useCreateColumns;
