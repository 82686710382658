import { useContext, createContext } from 'react';

function isFlagEnabled(flagName) {
  return process.env[`REACT_APP_${flagName}`] === 'true';
}

export const Context = createContext();

export const Provider = ({ featureValues = {}, children }) => {
  return (
    <Context.Provider
      value={{
        routesIsEnabled: isFlagEnabled('ROUTES_RELEASED'),
        routesPageIsEnabled: isFlagEnabled('ROUTES_PAGE_RELEASED'),
        usersTableIsEnabled: isFlagEnabled('USERS_VIEW_RELEASED'),
        lowResolutionShipmentBuilderIsEnabled: isFlagEnabled(
          'LOW_RESOLUTION_SHIPMENT_BUILDER'
        ),
        pricingRequestsIsEnabled: isFlagEnabled('PRICING_REQUESTS_RELEASED'),
        shipmentEventsIssuesEnabled: isFlagEnabled(
          'SHIPMENT_EVENT_ISSUES_ACTIVE'
        ),
        newEstimatedEventsActive: isFlagEnabled('NEW_ESTIMATED_EVENTS_ACTIVE'),
        customsDocumentsEnabled: isFlagEnabled('CUSTOMS_DOCUMENTS'),
        isNotificationsConfigHidden: isFlagEnabled(
          'HIDE_NOTIFICATIONS_CONFIG_FROM_TRACK_AND_TRACE'
        ),
        csAddMessageEnabled: isFlagEnabled('CS_ADD_MESSAGE_ENABLED'),
        ...featureValues,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useFeatures() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useFeatures must be used within a FeaturesProvider`);
  }
  return context;
}
