import * as yup from 'yup';
import { transformRouteLocationToPlace } from '../hooks';

export const EXACT_APPOINTMENT_TYPE = 'exact_time';

const envNumberCopies = process.env.REACT_APP_MAX_NUMBER_OF_SHIPMENT_COPIES;
const maximumNumberOfShipments = parseInt(envNumberCopies, 10);

if (isNaN(maximumNumberOfShipments) || !maximumNumberOfShipments) {
  throw new Error(
    `Environment variable REACT_APP_MAX_NUMBER_OF_SHIPMENT_COPIES must be set to a valid number. parseInt(${envNumberCopies}) gave "${maximumNumberOfShipments}" instead.`
  );
}

export const createValidationSchema = t =>
  yup.object().shape({
    clientResponsibleForBorderCrossing: yup
      .object()
      .when('legsCrossMexicanBorder', {
        is: true,
        then: yup.object().required(t('shipment-builder-required-error')),
      }),
    readyForPickup: yup
      .string()
      .nullable()
      .required(t('shipment-builder-required-error')),
    typeOfLoadUnloadAtDestination: yup
      .object()
      .required(t('shipment-builder-required-error')),
    typeOfLoadUnloadAtOrigin: yup
      .object()
      .required(t('shipment-builder-required-error')),
    requestType: yup.object().required(t('shipment-builder-required-error')),
    appointmentTypeAtDestination: yup
      .object()
      .required(t('shipment-builder-required-error')),
    appointmentTypeAtOrigin: yup
      .object()
      .required(t('shipment-builder-required-error')),
    upperBoundForPickupWindowAt: yup
      .string()
      .nullable()
      .when('appointmentTypeAtOrigin', {
        is: appointmentTypeAtOrigin =>
          appointmentTypeAtOrigin?.value !== EXACT_APPOINTMENT_TYPE,
        then: yup
          .string()
          .nullable()
          .required(t('shipment-builder-required-error'))
          .test(
            'must-be-after-readyForPickup',
            t('upper-bound-must-be-after-ready-for-pickup-error'),
            (upperBoundForPickupWindowAt, testContext) => {
              const readyForPickup = testContext.parent.readyForPickup;

              // Both dates must exist
              if (!upperBoundForPickupWindowAt || !readyForPickup) {
                return false;
              }

              // upperBoundForPickupWindowAt must be after readyForPickup
              return (
                new Date(upperBoundForPickupWindowAt).getTime() >
                new Date(readyForPickup).getTime()
              );
            }
          ),
      }),
    numberOfCopies: yup
      .number()
      .nullable()
      .min(2, t('shipment-builder-number-of-copies-gte-two'))
      .max(
        maximumNumberOfShipments,
        t('shipment-builder-number-of-copies-max', {
          max: maximumNumberOfShipments,
        })
      )
      .integer(t('shipment-builder-number-of-copies-integer')),
  });

export const createClientResponsibleForBorderCrossingOptions = t => [
  {
    label: t('yes-nuvocargo-manages-border-crossing'),
    value: false,
  },
  {
    label: t('no-client-manages-border-crossing'),
    value: true,
  },
];

export const createRequiresPitaOptions = t => [
  {
    label: t('no-requires-no-pita'),
    value: false,
  },
  {
    label: t('yes-requires-pita'),
    value: true,
  },
];

export const createRequestTypeOptions = t => [
  {
    label: t('request-type-spot'),
    value: 'spot',
  },
  {
    label: t('request-type-committed'),
    value: 'committed',
  },
];

export const createAppointmentTypeOptions = t => [
  {
    label: t('appointment-type-exact-time'),
    value: EXACT_APPOINTMENT_TYPE,
  },
  {
    label: t('appointment-type-window'),
    value: 'window',
  },
  {
    label: t('appointment-type-fifo'),
    value: 'fifo',
  },
];

export const createTrialPeriodOptions = t => [
  {
    label: t('trial-period-yes'),
    value: true,
  },
  {
    label: t('trial-period-no'),
    value: false,
  },
];

export const createTypeOfLoadUnloadOptions = t => [
  {
    label: t('load-unload-live-type'),
    value: 'live',
  },
  {
    label: t('load-unload-drop-type'),
    value: 'drop',
  },
];

export const createAdditionalServicesOptions = t => [
  { label: t('customs'), value: 'withCustoms' },
  { label: t('cross-Border-insurance'), value: 'withCrossBorderInsurance' },
  { label: t('financing'), value: 'withFinancing' },
];

export const ADDITIONAL_SERVICES = {
  CUSTOMS: 'customs',
  CROSS_BORDER_INSURANCE: 'cross_border_insurance',
  FINANCE: 'financing',
};

export const createInitialValues = ({
  appointmentTypeAtDestination,
  appointmentTypeAtOrigin,
  clientResponsibleForBorderCrossing,
  loadInstructions,
  representativeId,
  requestType,
  requiresPita,
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  legsCrossMexicanBorder,
  withCustoms,
  withCrossBorderInsurance,
  withFinancing,
  trialPeriod,
}) => ({
  appointmentTypeAtDestination,
  appointmentTypeAtOrigin,
  clientResponsibleForBorderCrossing,
  customerReference: '',
  invoiceToCompanyId: null,
  loadInstructions,
  numberOfCopies: '',
  readyForPickup: null,
  representativeId,
  requestType,
  requiresPita,
  state: 'active',
  status: 'accepted',
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  upperBoundForPickupWindowAt: null,
  legsCrossMexicanBorder,
  withCustoms,
  withCrossBorderInsurance,
  withFinancing,
  trialPeriod,
});

export const transformFormToRequestBody = ({
  // eslint-disable-next-line no-unused-vars
  company,
  appointmentTypeAtDestination,
  appointmentTypeAtOrigin,
  clientResponsibleForBorderCrossing,
  invoiceToCompanyId,
  legsCrossMexicanBorder,
  requestType,
  requiresPita,
  route,
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  upperBoundForPickupWindowAt,
  trialPeriod,
  ...rest
}) => ({
  appointmentTypeAtDestination: appointmentTypeAtDestination?.value ?? null,
  appointmentTypeAtOrigin: appointmentTypeAtOrigin?.value ?? null,
  // When legs don't cross Mexican border, we default to false
  clientResponsibleForBorderCrossing: legsCrossMexicanBorder
    ? clientResponsibleForBorderCrossing.value
    : false,
  invoiceToDifferentCompanyId: invoiceToCompanyId?.value ?? null,
  requestType: requestType.value,
  // When legs don't cross Mexican border, we default to false
  requiresPita: legsCrossMexicanBorder ? requiresPita.value : false,
  routeId: route.id,
  typeOfLoadUnloadAtDestination: typeOfLoadUnloadAtDestination.value,
  typeOfLoadUnloadAtOrigin: typeOfLoadUnloadAtOrigin.value,
  // Only window appointment types have an upper bound for pickup window value
  upperBoundForPickupWindowAt:
    appointmentTypeAtOrigin?.value !== EXACT_APPOINTMENT_TYPE
      ? upperBoundForPickupWindowAt
      : undefined,
  trialPeriod: trialPeriod.value,
  ...rest,
});

// We want te clear all the non-geolocation data from a Route for when we pass
// it to a modal
export const routeToCreateLocation = (location, companyId) => ({
  ...transformRouteLocationToPlace(location),
  companyId,
  addressLine1: '',
  addressLine2: '',
  contactName: '',
  contactTelephone: '',
  id: null,
  name: '',
});
