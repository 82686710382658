import {
  BackFormButton,
  NextFormButton,
} from 'screens/pricing-request/components';
import {
  pricingRequestStyles,
  ResizeContainerDiv,
} from 'screens/pricing-request/pricingRequest.styles';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import { generatePath, useParams } from 'react-router-dom';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { useLegPricesTable } from './hooks';
import { validationSchema } from './data';
import { Formik, Form } from 'formik';
import { app } from 'routes/paths';

export const LegPrices = ({ state: { legQuotes }, dispatch }) => {
  const { t } = useTranslation();
  const { id: requestId } = useParams();
  const { columns, onSubmit } = useLegPricesTable({ dispatch });

  if (!columns || !legQuotes) {
    return <></>;
  }

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">{t('leg-prices-title')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Heading variant="h6" color="steel">
          {t('leg-prices-details-text-label')}
        </Heading>
      </Stack>
      <Formik
        enableReinitialize
        validationSchema={validationSchema(t)}
        initialValues={{
          legQuotes: legQuotes || [],
        }}
        onSubmit={onSubmit}>
        <Form>
          <InputTable
            hideActions
            name="legQuotes"
            columnIdentifier="id"
            columns={columns}
          />

          <div style={pricingRequestStyles.buttonContainer}>
            <BackFormButton
              route={generatePath(app.pricingRequestsLegCostsDetails, {
                id: requestId,
              })}
            />
            <NextFormButton />
          </div>
        </Form>
      </Formik>
    </ResizeContainerDiv>
  );
};
