import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 90px 36px 0px;
`;
FormWrapper.displayName = 'FormWrapper';

export const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 42px;
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.softGrey};
`;
Footer.displayName = 'Footer';

export const FormSpacer = styled.div`
  margin-bottom: 18px;
`;
FormSpacer.displayName = 'FormSpacer';

export const PhoneGroup = styled.div`
  padding-top: 18px;
  border-top: 1px solid ${({ theme }) => theme.colors.softGrey};
`;
PhoneGroup.displayName = 'PhoneGroup';
