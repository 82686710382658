const SERVICE_TYPES = {
  DIRECT: 'DIRECT',
  UNKNOWN: 'UNKOWN',
  TRANSLOAD: 'TRANSLOAD',
  INDIFFERENT: 'INDIFFERENT',
};

export const calculateRoutePrice = (legQuotes, routeId) => {
  return legQuotes.reduce((acc, legQuote) => {
    if (!legQuote.routeIds.includes(routeId)) {
      return acc;
    }

    const { legCostCents, marginCents } = legQuote;
    return acc + Number(marginCents) + legCostCents;
  }, 0);
};

export const isDirectService = serviceType => {
  return serviceType === SERVICE_TYPES.DIRECT;
};

export const isTransloadService = serviceType => {
  return serviceType === SERVICE_TYPES.TRANSLOAD;
};
