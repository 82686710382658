import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/common/app';
import { getEstimatedDateFromShipmentEvent } from './data';
import { formatShortDateTime } from 'core/utils/date-fns';
import { shipmentEventTypeEnum } from '../../utils';
import EstimatedRoute from 'components/generic/kit/EstimatedRoute';

const RouteWrapper = styled.div`
  display: flex;
  padding: 32px;
`;

export default function Details({ shipment }) {
  const { t } = useTranslation();

  const pickupDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.ARRIVED_TO_ORIGIN
  );

  const deliveryDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.ARRIVED_TO_DESTINATION
  );

  const getEstimatedDate = date =>
    date
      ? t('estimated-at', {
          estimatedDate: formatShortDateTime(date),
        })
      : t('estimated-pending');

  return (
    <CardWrapper>
      <Card noBorder>
        <RouteWrapper>
          <EstimatedRoute
            title={t('pickup')}
            location={shipment.origin}
            estimatedDatetime={getEstimatedDate(pickupDate)}
          />
          <EstimatedRoute
            title={t('delivery')}
            location={shipment.destination}
            estimatedDatetime={getEstimatedDate(deliveryDate)}
            styles={{ marginLeft: '110px' }}
          />
        </RouteWrapper>
      </Card>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;
CardWrapper.displayName = 'CardWrapper';
