import React from 'react';
import { setDefaultLocale } from 'react-datepicker';
import {
  AuthenticatedHeader,
  PageHeaderProvider,
} from '@nuvocargo/nuvo-styleguide';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useUser, useFeatures } from 'context';
import { signOut, updateLanguage } from 'core/api/user';
import { app } from 'routes';
import {
  capitalizeString,
  hasMultipleRoles,
  canSeePricingRequests,
  canSeeRoutes,
  canSeeTrackAndTrace,
} from 'core/utils';

export default function AuthenticatedLayout({ children }) {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { currentUser, currentRole, setCurrentRole } = useUser();
  const { routesPageIsEnabled, pricingRequestsIsEnabled } = useFeatures();
  const { mutate: signOutMutation } = useMutation(signOut, {
    /**
     * OnSignOut update the query cache to set `check-auth` to initial state
     * Update the UI before sign out request is completed. 🔥 Optimistic UI ⚡️!!
     */
    onMutate: () => {
      // If we are logging out we cancel all possible outgoing queries queries
      queryClient.cancelQueries();

      const previousValue = queryClient.getQueryData('check-auth');
      queryClient.setQueryData('check-auth', () => ({
        hasValidCredentials: false,
        role: 'no_role_set',
        currentUser: null,
        currentRole: 'no_role_set',
      }));
      setCurrentRole('no_role_set');

      return previousValue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries('check-auth');
    },
  });

  const dropDownLinks = [
    {
      icon: i18n.language === 'en' ? 'usa' : 'mexico',
      text: i18n.language.toUpperCase(),
      onClick: () => {
        const newLanguage = i18n.language === 'en' ? 'es' : 'en';

        updateLanguage(newLanguage);
        setDefaultLocale(newLanguage);
        i18n.changeLanguage(newLanguage);
      },
    },
    {
      text: t('log-out'),
      onClick: signOutMutation,
    },
  ];

  const rolesLinks = currentUser.roles.map(role => {
    return {
      text: `${t('change-role-to')} ${role.replace(/_/g, ' ')}`,
      onClick: () => {
        setCurrentRole(role);
      },
    };
  });

  return (
    <PageHeaderProvider>
      <AuthenticatedHeader
        styles={{
          desktop: {
            zIndex: 9,
          },
        }}
        logoProps={{
          component: Link,
          props: {
            to: '/',
          },
        }}
        links={
          <>
            {pricingRequestsIsEnabled && canSeePricingRequests(currentRole) && (
              <NavLink to={app.pricingRequests}>
                {t('pricing-requests-navigation-bar')}
              </NavLink>
            )}
            {routesPageIsEnabled && canSeeRoutes(currentRole) && (
              <NavLink to={app.routes}>{t('routes-navigation-bar')}</NavLink>
            )}
            {canSeeTrackAndTrace(currentRole) && (
              <NavLink to={app.trackAndTrace}>
                {t('tracking-navigation-bar')}
              </NavLink>
            )}
          </>
        }
        menuProps={{
          text: `${t('hello')}, ${currentUser?.displayName}`,
          subText: capitalizeString(currentRole?.replace(/_/g, ' ')),
          options: hasMultipleRoles(currentUser.roles)
            ? rolesLinks.concat(dropDownLinks)
            : dropDownLinks,
        }}
      />
      {children}
    </PageHeaderProvider>
  );
}
