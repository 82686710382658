import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  InputCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { getLegQuotesByIdentifier } from 'core/api';
import { toCurrency } from 'core/utils/inputToCurrency';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import { ACTIONS_TYPES } from '../../store/reducer';

const createLegCostsColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-volume-text-label'),
        accessor: 'volume',
        type: 'text',
        width: '96px',
        minWidth: '96px',
        maxWidth: '96px',
        fontColor: 'green',
      },
      {
        Header: t('general-base-rate-estimate-text-label'),
        accessor: 'baseRate',
        Cell: ({ value, onChange, ...rest }) => {
          return (
            <InputCell
              value={value}
              onChange={e => onChange(toCurrency(e.target.value))}
              leftComponent={<Icon name="attachMoney" />}
              {...rest}
            />
          );
        },
      },
    ],
    []
  );
  return columns;
};

const UseLegCosts = ({ requestId, dispatch }) => {
  const { isLoading } = useQuery(
    ['legQuotesRequests', requestId],
    () => getLegQuotesByIdentifier({ requestIdentifier: requestId }),
    {
      onSuccess: data => {
        dispatch({
          type: ACTIONS_TYPES.SET_LEG_QUOTES_FROM_SERVER,
          payload: data,
        });
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const columns = createLegCostsColumns();
  return {
    columns,
    isLoading,
  };
};

export default UseLegCosts;
