import { useQuery } from 'react-query';

import { gql } from 'graphql-request';
import { client } from 'core/utils/axios.config';

const GET_ASSIGNEES_QUERY = gql`
  query getUsers($internalRole: String) {
    users(internalRole: $internalRole) {
      nodes {
        id
        name
      }
    }
  }
`;

export const getAssignees = () =>
  client
    .gql({
      query: GET_ASSIGNEES_QUERY,
      variables: {
        internalRole: 'pricing_representative',
      },
    })
    .then(r => r?.data?.data?.users.nodes ?? []);

export const useAssignees = queryOptions =>
  useQuery('assignees', () => getAssignees(), queryOptions);
