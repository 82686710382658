import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as FullStory from '@fullstory/browser';

import { formatAddress } from 'core/utils';
import { pick } from 'ramda';
import { client } from 'core/utils/axios.config';
import { gql } from 'graphql-request';

export const usePlacesRequest = ({ geolocationId, companyId }, queryOptions) =>
  useQuery(
    ['places', companyId, geolocationId],
    () =>
      getPlaces({
        geolocationId,
        companyId,
      }),
    queryOptions
  );

export const useUpdatePlace = onSuccessCallback => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      updatePlace({
        body: {
          ...pick(
            [
              'name',
              'addressLine1',
              'addressLine2',
              'contactName',
              'contactTelephone',
            ],
            body
          ),
          id: `${body.id}`,
        },
      }),
    {
      onSuccess: updatedPlace => {
        onSuccessCallback?.(updatedPlace);

        FullStory.event('Updated place information for route', {
          updatedPlace,
        });

        return queryClient.invalidateQueries(['route']);
      },
    }
  );
};

export const useCreatePlace = onSuccessCallback => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      createPlace({
        body: {
          ...pick(
            [
              'companyId',
              'geolocationId',
              'name',
              'addressLine1',
              'addressLine2',
              'contactName',
              'contactTelephone',
            ],
            body
          ),
        },
      }),
    {
      onSuccess: createdPlace => {
        onSuccessCallback?.(createdPlace);

        FullStory.event('Created a new place', {
          createdPlace,
        });

        return queryClient.invalidateQueries([
          'places',
          createdPlace.companyId,
          createdPlace.zipCode.id,
        ]);
      },
    }
  );
};

const GET_PLACES_QUERY = gql`
  query ($geolocationId: String, $companyId: String) {
    places(geolocationId: $geolocationId, companyId: $companyId) {
      id
      name
      addressLine1
      addressLine2
      contactName
      contactTelephone
      companyId
      city {
        name
      }
      state {
        name
      }
      zipCode {
        id
        name
        latitude
        longitude
      }
      country {
        name
      }
    }
  }
`;

const getPlaces = ({ companyId, geolocationId }) =>
  client
    .gql({
      query: GET_PLACES_QUERY,
      variables: {
        companyId: companyId.toString(),
        geolocationId,
      },
    })
    .then(r => r?.data?.data?.places ?? []);

const UPDATE_PLACE_MUTATION = gql`
  mutation ($input: UpdatePlaceInput!) {
    updatePlace(input: $input) {
      errors
      place {
        id
        name
        addressLine1
        addressLine2
        contactName
        contactTelephone
      }
    }
  }
`;

const updatePlace = ({ body }) =>
  client
    .gql({
      query: UPDATE_PLACE_MUTATION,
      variables: {
        input: body,
      },
    })
    .then(r => r.data?.data?.updatePlace?.place);

const CREATE_PLACE_MUTATION = gql`
  mutation ($input: CreatePlaceInput!) {
    createPlace(input: $input) {
      place {
        id
        name
        addressLine1
        addressLine2
        contactName
        contactTelephone
        companyId
        zipCode {
          id
          name
          latitude
          longitude
        }
        city {
          name
        }
        state {
          name
        }
        country {
          name
        }
      }
    }
  }
`;

const createPlace = ({ body }) =>
  client
    .gql({
      query: CREATE_PLACE_MUTATION,
      variables: {
        input: {
          attributes: body,
        },
      },
    })
    .then(r => r.data?.data?.createPlace?.place);

export const formatPlace = ({
  addressLine1,
  addressLine2,
  city,
  companyId,
  contactName,
  contactTelephone,
  country,
  id,
  name,
  state,
  zipCode,
}) => ({
  addressLine1,
  addressLine2,
  city: city?.name,
  companyId,
  contactName,
  contactTelephone,
  country: country?.name,
  geolocationId: zipCode?.id,
  id,
  label: `${name} • ${formatAddress({
    addressLine1,
    addressLine2,
    zipCode: zipCode?.name,
    city: city?.name,
    state: state?.name,
    country: country?.name,
  })}`,
  latitude: zipCode?.latitude,
  longitude: zipCode?.longitude,
  name,
  state: state?.name,
  value: id,
  zipCode: zipCode?.name,
});

export const placeHasData = ({ addressLine1, name, contactTelephone }) =>
  addressLine1 && name && contactTelephone;
