import { Eyebrow, Icon } from '@nuvocargo/nuvo-styleguide';
import { SmallDetails, Stack } from 'components/generic/kit';
import { graphQLCountryToFlagString } from 'screens/track-and-trace/utils';
import styled from 'styled-components';

const EstimatedRoute = ({ title, location, estimatedDatetime, styles }) => (
  <RouteWrapper style={{ ...styles }}>
    <Eyebrow color="steel" size="small" text={title} />
    <SmallDetails
      top={
        <Stack axis="horizontal">
          <Icon
            name={graphQLCountryToFlagString(location?.country)}
            styles={{ marginRight: '10px' }}
          />
          {location?.city}
        </Stack>
      }
      down={estimatedDatetime}
      topStyles={{ regular: { marginBottom: '4px' } }}
    />
  </RouteWrapper>
);

const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

RouteWrapper.displayName = 'RouteWrapper';

export default EstimatedRoute;
