import { ROUTE_SERVICEABLE_ENUM } from 'core/utils/routeUtils';
import { toCurrency } from 'core/utils/inputToCurrency';
import * as R from 'ramda';

const transformServiceableToObject = (value, t) => {
  if (
    value === ROUTE_SERVICEABLE_ENUM.SERVICEABLE ||
    value === ROUTE_SERVICEABLE_ENUM.UNDETERMINED
  ) {
    return {
      value: ROUTE_SERVICEABLE_ENUM.SERVICEABLE,
      label: t('serviceable-text-label'),
    };
  }

  return {
    value: ROUTE_SERVICEABLE_ENUM.NOT_SERVICEABLE,
    label: t('non-serviceable-text-label'),
  };
};

export const transformGetRoutesByRequestIdentifier = (rawData, t) => {
  return rawData?.data?.routes?.edges.map(edge => ({
    ...edge.node,
    ...(edge?.node?.serviceable
      ? {
          serviceable: transformServiceableToObject(edge.node.serviceable, t),
        }
      : {
          serviceable: transformServiceableToObject(
            ROUTE_SERVICEABLE_ENUM.SERVICEABLE,
            t
          ),
        }),
  }));
};

export const flatGetLegQuotesByRequestIdentifier = rawData => {
  return rawData?.data?.legQuotes?.edges.map(edge => ({
    ...edge.node,
  }));
};

const getBaseRate = (stateLegQuote, serverLegQuote) => {
  if (stateLegQuote) {
    return {
      baseRateCents: stateLegQuote?.baseRateCents,
      baseRate: toCurrency(`${stateLegQuote?.baseRateCents}`),
    };
  }
  if (serverLegQuote?.baseRateCents !== undefined) {
    return {
      baseRateCents: serverLegQuote?.baseRateCents,
      baseRate: toCurrency(`${serverLegQuote?.baseRateCents}`),
    };
  }

  return {
    baseRateCents: 0,
    baseRate: '0.00',
  };
};

const getMargin = (stateLegQuote, serverLegQuote) => {
  if (stateLegQuote?.margin) {
    return {
      marginCents: stateLegQuote?.marginCents,
      margin: toCurrency(`${stateLegQuote?.marginCents}`),
    };
  }
  if (serverLegQuote?.marginCents !== undefined) {
    return {
      marginCents: serverLegQuote?.marginCents,
      margin: toCurrency(`${serverLegQuote?.marginCents}`),
    };
  }

  return {
    marginCents: 0,
    margin: '0.00',
  };
};

const getDirectService = (stateLegQuote, serverLegQuote) => {
  if (stateLegQuote) {
    return {
      directPremiumCostCents: stateLegQuote?.directPremiumCostCents,
      directPremiumCost: toCurrency(`${stateLegQuote?.directPremiumCostCents}`),
    };
  }
  if (serverLegQuote?.directPremiumCostCents !== undefined) {
    return {
      directPremiumCostCents: serverLegQuote?.directPremiumCostCents,
      directPremiumCost: toCurrency(
        `${serverLegQuote?.directPremiumCostCents}`
      ),
    };
  }

  return {
    directPremiumCostCents: 0,
    directPremiumCost: '0.00',
  };
};

export const addBaseRateFromBaseRateCents = (state = {}, dataFromServer) => {
  return dataFromServer?.map(legQuote => {
    const stateLegQuote = state.legQuotes?.find(
      stateLegQuote => stateLegQuote.id === legQuote.id
    );

    return {
      ...legQuote,
      ...getBaseRate(stateLegQuote, legQuote),
      ...getMargin(stateLegQuote, legQuote),
      ...getDirectService(stateLegQuote, legQuote),
    };
  });
};

const getAccessorialsTotalCostCents = (accessorials = []) => {
  return accessorials.reduce(
    (acc, accessorial) => (acc += accessorial.totalCostCents),
    0
  );
};

export const updateLegQuoteDataFromUserBaseRate = data => {
  return data?.map(legQuote => {
    const fuelCostCents = legQuote.fuelCostCents || 0;
    const accessorialsTotalCostCents = getAccessorialsTotalCostCents(
      legQuote?.accessorials
    );
    return {
      ...legQuote,
      baseRateCents: parseInt(legQuote?.baseRate * 100),
      legCostCents: parseInt(
        legQuote.baseRate * 100 + fuelCostCents + accessorialsTotalCostCents
      ),
      accessorialsTotalCostCents,
    };
  });
};

export const updateLegQuoteDataFromUserPremiumRate = data => {
  return data?.map(legQuote => {
    const directPremiumCostCents = parseInt(
      legQuote.directPremiumCost * 100 || 0
    );
    return {
      ...legQuote,
      directPremiumCostCents,
      legCostCents: parseInt(legQuote?.legCostCents + directPremiumCostCents),
    };
  });
};

export const updateLegQuoteDataFromUserMargin = data => {
  return data?.map(legQuote => {
    return {
      ...legQuote,
      marginCents: parseInt(legQuote?.margin * 100),
    };
  });
};

export const filterServiceableLegQuotes = (state, newLegQuotes) => {
  let validLegQuotes = [];
  const serviceableRoutes = state.routes.filter(
    route => route.serviceable.value === ROUTE_SERVICEABLE_ENUM.SERVICEABLE
  );
  serviceableRoutes.forEach(route => {
    const currentLegQuotes = newLegQuotes.filter(legQuote =>
      legQuote.routeIds.includes(route.id)
    );
    validLegQuotes = R.uniq([...validLegQuotes, ...currentLegQuotes]);
  });
  return validLegQuotes;
};
