import React from 'react';
import { TableWithFilters } from 'components/common/app';
import { useTranslation } from 'react-i18next';
import { Modal } from '@nuvocargo/nuvo-styleguide';
import { useUsers } from './hooks';
import useCreateColumns from './useCreateColumns';
import UpdateUser from './UpdateUser';

export default function Users() {
  const {
    email,
    isLoading,
    page,
    qp,
    setEmail,
    setUserId,
    totalPages,
    userId,
    users,
  } = useUsers();
  const { t } = useTranslation();
  const closeModal = () => {
    setUserId();
  };

  return (
    <>
      <TableWithFilters
        columns={useCreateColumns({
          email,
          page,
          qp,
          setUserId,
          t,
        })}
        data={users}
        isLoading={isLoading}
        page={page}
        searchId="users-table-search"
        searchOnChange={setEmail}
        searchPlaceholder={t('users-table-search', 'Search')}
        searchValue={email}
        tableTitle={t('users-table-title')}
        totalPages={totalPages}
      />

      <Modal
        isOpen={!!userId}
        closeModal={closeModal}
        variant="rightSidebar"
        styles={{
          sidebar: {
            width: '764px',
          },
          close_button: {
            right: '32px',
            top: '14px',
          },
        }}>
        {!!userId && (
          <UpdateUser
            closeUpdateUserModal={closeModal}
            updateUser={() => {}}
            userId={userId}
          />
        )}
      </Modal>
    </>
  );
}
