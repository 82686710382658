import { useEffect, useState } from 'react';
import useQueryParamsState from './useQueryParamsState';

const usePageQueryParam = (pageResetEffect = []) => {
  const [page = 1, setPage] = useQueryParamsState('page');
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    /**
     * This is a hack which prevents the first render from resetting our page,
     * that way any kind of reload/remount resets our page progress, which
     * applied to fresh loads.
     */
    if (!isFirstRender) {
      setPage(1);
    } else {
      setIsFirstRender(false);
    }
  }, pageResetEffect);

  const parsedPage = typeof page === 'number' ? page : parseInt(page, 10) || 1;

  return [parsedPage, setPage];
};

export default usePageQueryParam;
