import React from 'react';

// Utils
import { REQUEST_TYPE_OPTIONS } from 'core/utils/routeUtils';

// UI & UX
import { Stack } from 'components/generic/kit';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { createRequestQuoteServiceTypeOptions } from '../../../screens/quote-request/data';
import { buildYesNoDropdownOptions } from 'core/utils';

export default function RequestTypeAndCurrencyFields() {
  const { t } = useTranslation();

  return (
    <>
      <Stack axis="horizontal" space="25px">
        <Dropdown
          name="request_type"
          label="What type of rate is this?"
          styles={{ root: { width: '100%' } }}
          options={REQUEST_TYPE_OPTIONS}
        />
        <Dropdown
          name="currency"
          label="Currency"
          styles={{ root: { width: '100%' } }}
          options={[
            { label: 'USD', value: 'usd' },
            { label: 'MXN', value: 'mxn' },
          ]}
        />
      </Stack>
      <Stack
        axis="horizontal"
        space="25px"
        styles={{ root: { marginTop: '24px' } }}>
        <Dropdown
          name="service_type"
          label="Service type"
          styles={{ root: { width: '100%' } }}
          options={createRequestQuoteServiceTypeOptions(t)}
        />
        <Dropdown
          name="border_crossing"
          label="Include Border Crossing?"
          styles={{ root: { width: '100%' } }}
          options={buildYesNoDropdownOptions(t)}
        />
      </Stack>
    </>
  );
}
