import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  QuoteRequest,
  RouteDetails,
  Routes,
  Success,
  TrackAndTrace,
  Users,
  LofiCreateShipment,
  NewRoute,
  PricingRequests,
  PricingRequest,
  Customs,
} from 'screens';
import { GlobalBanner } from 'components/common/app';
import { AuthenticatedLayout as Layout } from 'components/common/layout';
import { useFeatures, useUser } from 'context';
import { app, auth, customs } from './paths';
import { canSeePricingRequests } from '../core/utils';

export default function AuthenticatedRoutes() {
  const { currentRole } = useUser();

  const {
    routesPageIsEnabled,
    usersTableIsEnabled,
    lowResolutionShipmentBuilderIsEnabled,
    pricingRequestsIsEnabled,
  } = useFeatures();

  return (
    <>
      <Switch>
        <Route exact path={app.quoteRequest}>
          <QuoteRequest />
        </Route>
        <Route exact path="/">
          <Redirect to={app.trackAndTrace} />
        </Route>
        <Route exact path={app.success}>
          <Success />
        </Route>
        <Route exact path={auth.login}>
          <Redirect to={app.main} />
        </Route>
        <Route exact path={app.trackAndTrace}>
          <Layout>
            <GlobalBanner />
            <TrackAndTrace />
          </Layout>
        </Route>
        <Route exact path={customs.main}>
          <Layout>
            <GlobalBanner />
            <Customs />
          </Layout>
        </Route>
        <Route exact path={app.newRoute}>
          <Layout>
            <GlobalBanner />
            <NewRoute />
          </Layout>
        </Route>
        {routesPageIsEnabled && (
          <Route path={app.routes}>
            <Layout>
              <GlobalBanner />

              <Route exact path={app.routes}>
                <Routes />
              </Route>

              <Route exact path={app.route}>
                <RouteDetails />
              </Route>

              {lowResolutionShipmentBuilderIsEnabled && (
                <Route exact path={app.lofiCreateShipment}>
                  <LofiCreateShipment />
                </Route>
              )}
            </Layout>
          </Route>
        )}

        {usersTableIsEnabled && (
          <Route exact path={app.users}>
            <Layout>
              <GlobalBanner />
              <Users />
            </Layout>
          </Route>
        )}
        {pricingRequestsIsEnabled && canSeePricingRequests(currentRole) ? (
          <>
            <Route exact path={app.pricingRequests}>
              <Layout>
                <GlobalBanner />
                <PricingRequests />
              </Layout>
            </Route>
            <Route path={app.pricingRequest}>
              <Layout>
                <PricingRequest />
              </Layout>
            </Route>
          </>
        ) : (
          <Redirect to={app.main} />
        )}
      </Switch>
    </>
  );
}
