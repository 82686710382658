import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import { CTAButton, Text, Heading, Link } from '@nuvocargo/nuvo-styleguide';
import {
  Dropdown,
  Textarea,
  TextField,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Stack } from 'components/generic/kit';
import { openBanner } from 'core/hooks/useBanner';
import { useUpdateUser } from './hooks';
import { Footer, FormSpacer, FormWrapper, PhoneGroup } from './styles';
import PhoneNumber from '@nuvocargo/nuvo-styleguide/forms/formik/PhoneNumber';

const UpdateUser = ({ closeUpdateUserModal, userId }) => {
  const { t } = useTranslation();
  const { isLoading, updateUser, options, initialValues } = useUpdateUser({
    userId,
    onSuccessCallback: () => {
      closeUpdateUserModal();

      openBanner({
        key: 'users-update-form-success',
        timeout: 5000,
        children: () => (
          <Text color="white">
            {t('users-update-form-success-banner-message')}
          </Text>
        ),
      });
    },
    t,
  });

  return (
    <FormWrapper>
      <FormSpacer>
        <Heading variant="h4" color="green">
          {t('update-user-form-heading')}
        </Heading>
      </FormSpacer>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={values =>
          updateUser({
            ...values,
            phoneNumbers: values.phoneNumbers.map(n => ({
              ...n,
              type: n.type?.value,
            })),
          })
        }>
        {({ values }) => (
          <Form>
            <Stack>
              <FormSpacer>
                <TextField
                  label={t('update-user-form-email')}
                  name="email"
                  disabled
                  styles={{
                    input: {
                      cursor: 'not-allowed',
                    },
                  }}
                />
              </FormSpacer>

              <FormSpacer>
                <TextField
                  label={t('update-user-form-first-name')}
                  name="firstName"
                  disabled={isLoading}
                />
              </FormSpacer>

              <FormSpacer>
                <TextField
                  label={t('update-user-form-last-name')}
                  name="lastName"
                  disabled={isLoading}
                />
              </FormSpacer>

              <FieldArray name="phoneNumbers">
                {({ remove, push }) => (
                  <div>
                    <Stack
                      axis="horizontal"
                      styles={{
                        root: {
                          justifyContent: 'flex-end',
                          margin: '18px 0px',
                        },
                      }}>
                      <Link
                        as="span"
                        onClick={() =>
                          push({
                            type: '',
                            number: '',
                            notes: '',
                          })
                        }>
                        {t('update-user-form-add-phone-number')}
                      </Link>
                    </Stack>
                    {values.phoneNumbers.length > 0 &&
                      values.phoneNumbers.map((_, index) => (
                        <PhoneGroup key={index}>
                          <Stack
                            axis="horizontal"
                            styles={{
                              root: {
                                justifyContent: 'space-between',
                              },
                            }}>
                            <Heading variant="h6" color="green">
                              {t('update-user-form-phone-group', {
                                phoneIndex: index + 1,
                              })}
                            </Heading>

                            <Link as="span" onClick={() => remove(index)}>
                              {t('update-user-form-remove-phone-number')}
                            </Link>
                          </Stack>

                          <FormSpacer>
                            <Dropdown
                              id={`phoneNumbers-${index}-type`}
                              label={t('update-user-form-type')}
                              name={`phoneNumbers.${index}.type`}
                              disabled={isLoading}
                              placeholder={t(
                                'update-user-form-type-placeholder'
                              )}
                              options={options}
                              className="UpdateUserContainer"
                              classNamePrefix="UpdateUser"
                            />
                          </FormSpacer>

                          <FormSpacer>
                            <PhoneNumber
                              label={t('update-user-form-number')}
                              name={`phoneNumbers.${index}.number`}
                              disabled={isLoading}
                            />
                          </FormSpacer>

                          <FormSpacer>
                            <Textarea
                              label={t('update-user-form-notes')}
                              name={`phoneNumbers.${index}.notes`}
                              disabled={isLoading}
                            />
                          </FormSpacer>
                        </PhoneGroup>
                      ))}
                  </div>
                )}
              </FieldArray>

              <Footer>
                <Text style={{ marginRight: '32px' }}>
                  {t('update-user-form-added-text')}
                </Text>

                <CTAButton
                  id="update-user-form-submit-button"
                  colorType="primary"
                  type="submit"
                  disabled={isLoading}
                  styles={{
                    primary: {
                      width: '167px',
                    },
                  }}>
                  {t('update-user-form-submit-button')}
                </CTAButton>
              </Footer>
            </Stack>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default UpdateUser;
