// Utils
import { has } from 'ramda';
import { isEmpty, find } from 'lodash';
import { buildYesNoDropdownOptions } from './lib';

import {
  centsToDollars,
  formatMoney,
  formatMoneyWithCurrency,
  formatWeight,
} from './lib';
import { formatShortDate } from './date-fns';

export const SPECIAL_REQUIREMENTS = t => ({
  // direct_service: 'Direct service (no transloading)',
  // hazmat: 'Hazmat',
  // fumigation: 'Fumigation',
  // straps_needed: 'Straps needed',
  team_driver: t('route-details-team-driver'),
  ctpat: t('general-ctpat-label'),
});

export const ADDITIONAL_SERVICES = t => [
  { label: t('customs'), value: 'customs' },
  { label: t('cross-Border-insurance'), value: 'cross_border_insurance' },
  { label: t('financing'), value: 'financing' },
];

export const REQUEST_TYPE_OPTIONS = [
  { label: 'Contracted', value: 'contracted' },
  { label: 'Spot Rate', value: 'spot_rate' },
];

export const REQUEST_PRICE_STATUS_ENUM = {
  REQUESTED: 'REQUESTED',
  PRICED: 'PRICED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  UNDETERMINED: 'UNDETERMINED',
};

export const ROUTE_STATE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PRICING_REP_FILTER_UNASSIGNED = `unassigned`;

export const ROUTE_SERVICEABLE_ENUM = {
  SERVICEABLE: 'SERVICEABLE',
  NOT_SERVICEABLE: 'NOT_SERVICEABLE',
  UNDETERMINED: 'UNDETERMINED',
};

export const serviceType = values => {
  return values['service_type'].value;
};

export const isPrevSuccess = (route, results) => {
  return results?.[route.id]?.requestStatus === 'fulfilled';
};

export const isNotPrevSuccess = (route, results) =>
  !isPrevSuccess(route, results);

export const isInvalidRoute = route =>
  isEmpty(route.origin) || isEmpty(route.destination) || !route.loads_per_month;

export const isValidRoute = route => !isInvalidRoute(route);

export const shipmentRequirements = values => {
  let shipmentRequirements = [];
  values['equipment_options'].forEach(equipmentOption => {
    if (equipmentOption.required.value) {
      shipmentRequirements.push(equipmentOption.id);
    }
  });
  return shipmentRequirements;
};

export const handleBorderCrossing = values => values['border_crossing'].value;

export const variableFuel = values => values['fuel_pricing'] === 'dynamic';

export const anyRejected = data => {
  return find(data, { requestStatus: 'rejected' });
};

export const buildSpecialRequirementsOptions = t => [
  {
    id: 'ctpat',
    type: {
      text: SPECIAL_REQUIREMENTS(t).ctpat,
    },
    cost: '',
    required: buildYesNoDropdownOptions(t)[0],
  },
  {
    id: 'team_driver',
    type: {
      text: SPECIAL_REQUIREMENTS(t).team_driver,
      leftIcon: { name: 'usa' },
    },
    cost: '',
    required: buildYesNoDropdownOptions(t)[0],
  },
];

// TODO: there is nothing currently to convert from camelCase to Title Case, this is a temporally fix
export const camel2title = camelCase =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();

export const camel2humanize = camelCase =>
  camelCase
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/(\b[a-z](?!\s))/g, match => match.toUpperCase())
    .trim();

const CURRENCY_FORMAT_OPTIONS = { minimumFractionDigits: 2 };

export const formatPrices = data => {
  const { company, convertedPriceCents, convertedFuelCostCents, currency } =
    data;

  const baseCost = convertedPriceCents - convertedFuelCostCents;

  return {
    routePrice: formatMoneyWithCurrency(
      centsToDollars(convertedPriceCents),
      currency,
      CURRENCY_FORMAT_OPTIONS
    ),
    baseCost: company.variableFuel
      ? formatMoneyWithCurrency(
          centsToDollars(baseCost),
          currency,
          CURRENCY_FORMAT_OPTIONS
        )
      : undefined,
    fuelCost: formatMoneyWithCurrency(
      centsToDollars(convertedFuelCostCents),
      currency,
      CURRENCY_FORMAT_OPTIONS
    ),
  };
};

export const whenRouteIs = (route, cases) => {
  if (route.status.is.active) {
    const hasCase = has('active');
    return hasCase(cases) ? cases.active : cases.fallback;
  }

  if (route.status.is.ready) {
    const hasCase = has('ready');
    return hasCase(cases) ? cases.ready : cases.fallback;
  }

  if (route.status.is.missingDetails) {
    const hasCase = has('missingDetails');
    return hasCase(cases) ? cases.missingDetails : cases.fallback;
  }

  if (route.status.is.expired) {
    const hasCase = has('expired');
    return hasCase(cases) ? cases.expired : cases.fallback;
  }

  if (route.status.is.unserviceable) {
    const hasCase = has('unserviceable');
    return hasCase(cases) ? cases.unserviceable : cases.fallback;
  }

  if (route.status.is.requested) {
    const hasCase = has('requested');
    return hasCase(cases) ? cases.requested : cases.fallback;
  }

  return cases.fallback;
};

export const formatDetails = (data, t) => {
  const {
    id,
    handleBorderCrossing,
    serviceType,
    truckType,
    commodities,
    shipmentRequirements,
    lastUpdatedAt,
    company: { legalName: companyName },
  } = data;

  const routeDetails = {
    routeId: `RT-${id}`,
    companyName,
    ...(lastUpdatedAt && {
      lastUpdatedAt: formatShortDate(lastUpdatedAt),
    }),
    ...(truckType && { equipment: t(`route-equipment-${truckType}`) }),
    ...(serviceType && { serviceType: t(`route-serviceType-${serviceType}`) }),
    includeBorderCrossing: handleBorderCrossing
      ? t('route-includeBorderCrossing-yes')
      : t('route-includeBorderCrossing-no'),
  };

  const commodity = commodities?.edges[0].node;

  if (commodity) {
    const { name, category, weight, valuePerLoadCents } = commodity;
    routeDetails.commodity = {
      name,
      category: t(`route-commodity-${category}`),
      weight: weight ? formatWeight(weight) : t('route-commodity-weight-n/a'),
      value: formatMoney(
        centsToDollars(valuePerLoadCents),
        CURRENCY_FORMAT_OPTIONS
      ),
    };
  }

  if (shipmentRequirements.length > 0) {
    const requirements = shipmentRequirements.map(req =>
      t(`route-requirement-${req}`)
    );

    let accessorials = [];

    if (commodity) {
      accessorials = commodity.accessorials.map(acc => t(`${acc.name}`));
    }

    routeDetails.specialHandling = [
      ...new Set([...requirements, ...accessorials]),
    ];
  }

  return routeDetails;
};

const createPoint = point => ({
  longitude: +point.zipCode.longitude,
  latitude: +point.zipCode.latitude,
});

export const buildMapLegs = (origin, destination) => {
  return [
    {
      origin: createPoint(origin),
      destination: createPoint(destination),
    },
  ];
};

const COUNTRY_ABBR_MAP = {
  mx: 'Méx',
  us: 'USA',
};

export const formatAddressOneLine = ({ addressLine1, zipCode }) => {
  const {
    name: zipCodeName,
    parents: { city, state, country },
  } = zipCode;

  return `${addressLine1}, ${city}, ${zipCodeName} ${state}, ${COUNTRY_ABBR_MAP[country]}`;
};

// TODO: refactor/DRY up these routeUtil/ util methods
