import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import NextFormButton from '../../components/NextFormButton';
import { Formik, Form } from 'formik';
import { app } from 'routes/paths';
import useLegCostsDetails from './useLegCostsDetails';
import LegCostDetailsTable from './legCostDetailsTable';
import BackFormButton from '../../components/BackFormButton';
import { FuelCostWrapper } from './legCostDetailsStyles';
import { isDirectService } from 'core/utils';
import {
  pricingRequestStyles,
  ResizeContainerDiv,
} from 'screens/pricing-request/pricingRequest.styles';

const LegCostsDetails = ({ state }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id: requestId } = useParams();
  const {
    details: { serviceType },
  } = state;
  const { columns } = useLegCostsDetails();

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">{t('leg-costs-details-title')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Text fontColor="steel">{t('leg-costs-details-text-label')}</Text>
      </Stack>
      <Formik
        enableReinitialize
        initialValues={{
          legQuotes: state.legQuotes || [],
        }}
        onSubmit={() => {
          history.push(
            generatePath(app.pricingRequestsLegPrices, {
              id: requestId,
            })
          );
        }}>
        <Form>
          <LegCostDetailsTable columns={columns} />
          <FuelCostWrapper>
            <Text size="extraSmall">
              {t('leg-costs-details-fuel-cost-copy')}
            </Text>
          </FuelCostWrapper>
          <div style={pricingRequestStyles.buttonContainer}>
            <BackFormButton
              route={generatePath(
                isDirectService(serviceType)
                  ? app.pricingRequestsDirectServicePremium
                  : app.pricingRequestsLegCosts,
                {
                  id: requestId,
                }
              )}
            />
            <NextFormButton />
          </div>
        </Form>
      </Formik>
    </ResizeContainerDiv>
  );
};

export default LegCostsDetails;
