import { Table } from '@nuvocargo/nuvo-styleguide/Table';
import { usePricingRequestsTable } from './hooks';

export const PricingRequestsTable = () => {
  const { data, columns, isLoading, totalItems, onRowClickHandler } =
    usePricingRequestsTable();

  return (
    <>
      <Table
        autoFilters={{ enabled: true }}
        columns={columns}
        data={data}
        empty={{}}
        isLoading={isLoading}
        onRowClick={onRowClickHandler}
        pagination={{
          totalItems,
        }}
      />
    </>
  );
};
