import { Heading, Search, SelectBox } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';

export default function FiltersSection({
  filters,
  filterStylesOverride,
  selected,
  onFilterChange,
  tableTitle,
  searchId,
  searchOnChange,
  searchValue,
  searchPlaceholder,
  rightComponent = null,
}) {
  return (
    <Stack
      axis="horizontal"
      space="24px"
      styles={{
        root: {
          margin: '60px 60px 0px 60px',
          justifyContent: 'space-between',
        },
      }}>
      <Heading variant="h3" color="forest">
        {tableTitle}
      </Heading>
      <Stack axis="horizontal" space="24px">
        {searchId && searchOnChange && (
          <Search
            id={searchId}
            placeholder={searchPlaceholder}
            onChange={e => {
              searchOnChange(e.target.value);
            }}
            onCloseCross={() => {
              searchOnChange('');
            }}
            value={searchValue}
          />
        )}

        {filters &&
          filters.map(filter => {
            if (!filter.items) {
              return null;
            }
            return (
              <SelectBox
                isFilterable
                label=""
                id={filter.field}
                key={filter.field}
                options={filter.items}
                value={selected(filter)}
                onChange={item => {
                  if (!item) return;
                  const { value } = item;
                  onFilterChange({
                    field: filter.field,
                    value,
                  });
                }}
                className="TableFiltersContainer"
                classNamePrefix="TableFilters"
                styles={filterStylesOverride}
              />
            );
          })}

        {rightComponent}
      </Stack>
    </Stack>
  );
}
