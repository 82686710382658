/* eslint-disable react/display-name */
import { TableWithFilters } from 'components/common/app';
import { useFeatures, useUser } from 'context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShipments, useShipmentsSidebar } from './hooks';
import ShipmentDetailsScreen from './shipment-details';
import useCreateColumns from './useCreateColumns';
import createStateOptions from './createStateOptions';
import ShipmentIssuesScreen from './shipment-details/ShipmentIssues';

export default function TrackAndTrace() {
  const { shipmentEventsIssuesEnabled } = useFeatures();
  const { t } = useTranslation();
  const {
    fileName,
    filters,
    isLoading,
    onFilterChange,
    page,
    selected,
    setFileName,
    setState,
    shipments,
    state,
    totalPages,
  } = useShipments();
  const { currentRole } = useUser();
  const { shipmentId, shipmentDetailsView, updateShipmentsSidebar, qp } =
    useShipmentsSidebar();
  const columns = useCreateColumns({ t, currentRole, state, qp });
  const statusOptions = createStateOptions({ t, currentRole });

  return (
    <>
      <TableWithFilters
        columns={columns}
        data={shipments}
        filters={filters}
        isLoading={isLoading}
        onFilterChange={onFilterChange}
        page={page}
        searchId="track-and-trace-search"
        searchOnChange={setFileName}
        searchPlaceholder={t('track-and-trace-search')}
        searchValue={fileName}
        selected={selected}
        selectedStatus={state}
        statusOnChange={setState}
        statusOptions={statusOptions}
        tableTitle={t('track-and-trace-title')}
        totalPages={totalPages}
      />

      <ShipmentDetailsScreen
        shipmentId={shipmentId}
        shipmentDetailsView={shipmentDetailsView}
        closeShipmentDetailsView={() =>
          updateShipmentsSidebar({ remove: true })
        }
      />

      {shipmentEventsIssuesEnabled && (
        <ShipmentIssuesScreen
          shipmentId={shipmentId}
          shipmentDetailsView={shipmentDetailsView}
          closeShipmentDetailsView={() =>
            updateShipmentsSidebar({ remove: true })
          }
        />
      )}
    </>
  );
}
