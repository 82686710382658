import { SmallDetails } from 'components/generic/kit';
import { formatShortDateTime } from 'core/utils/date-fns';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

export default function EtaToBorderCol({ cell }) {
  const borderArrivalEvent = cell.value.find(
    event => event.node.type === 'ARRIVED_TO_BORDER'
  );
  const { estimatedDatetime, actualDatetime } = borderArrivalEvent.node;
  const isEstimated = isNil(actualDatetime);
  const dateToDisplay = isEstimated ? estimatedDatetime : actualDatetime;

  return (
    <SmallDetails
      top={formatShortDateTime(dateToDisplay)}
      down={isEstimated ? 'Estimated' : 'Arrived'}
    />
  );
}
