const PLACE_FIELDS_FRAGMENT = `
  fragment placeFields on Place {
    id
    geolocationId
    name
    type
    contactName
    contactTelephone
    addressLine1
    addressLine2
    zipCode {
      id
      name
      longitude
      latitude
      parents {
        city
        state
        country
      }
    }
    facilityProcedure {
      appointmentType
      facilityInstructions
      loadingScheme
      loadingDuration
      placeId
      receivingDays
      startTime
      endTime
    }
  }
`;

const COMPANY_FIELDS_FRAGMENT = `
  fragment companyFields on Company {
      id
      name
      legalName
      billingCountry
      createdAt
      businessType
      taxIdNum
      financeHold
      trialPeriod
  }
`;

const COMMODITY_FIELDS_FRAGMENT = `
  fragment commodityFields on Commodity {
    id
    name
    weight
    category
    valuePerLoadCents
    requirements
    accessorials {
      name
    }
  }
`;

const REQUESTER_FIELDS_FRAGMENT = `
  fragment requesterFields on User {
    id
    email
    displayName
    firstName
    lastName
    language
    title
  }
`;

export const GET_ROUTE_QUERY_BY_ID = `
  query getRouteByID(
    $routeId: ID!
  ) {
    routes(id: $routeId) {
      nodes {
        id
        createdAt
        state
        priceState
        locationState
        notes
        length
        width
        height
        temperature
        truckType
        requestedPlatform
        serviceType
        shipmentRequirements
        numberOfPieces
        currency
        priceCents
        pricedAt
        convertedPriceCents
        loadsPerMonth
        handleBorderCrossing
        timeInTransit
        additionalServices
        status {
          is
        }
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        company {
          ...companyFields
        }
        commodities {
          edges {
            node {
              ...commodityFields
            }
          }
        }
        requester {
          ...requesterFields
        }
      }
    }
  }

  ${PLACE_FIELDS_FRAGMENT}
  ${COMPANY_FIELDS_FRAGMENT}
  ${COMMODITY_FIELDS_FRAGMENT}
  ${REQUESTER_FIELDS_FRAGMENT}`;
