import { useFeatures, useUser } from 'context';
import { stringify } from 'qs';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetShipments, useCompanies, useUsersRequest } from 'core/api';
import {
  useQueryParamsState,
  usePageQueryParam,
  useQueryParams,
} from 'core/hooks';
import { isCustomerSuccess, createFilters } from 'core/utils';
import {
  calculateShipmentStatusColumnValue,
  createGraphQLCursorParams,
  calculateTotalPagesFromGraphQL,
} from './utils';

const PER_PAGE_COUNT = 20;

export const useShipments = () => {
  const { t } = useTranslation();
  const { currentRole } = useUser();
  const { newEstimatedEventsActive } = useFeatures();
  const roleSlug = isCustomerSuccess(currentRole)
    ? 'customer_success'
    : 'carrier_representative';

  const [assigneeId = 'all', setAssigneeId] = useQueryParamsState('assigneeId');
  const [companyId = 'all', setCompanyId] = useQueryParamsState('companyId');
  const [state = 'ACTIVE', setState] = useQueryParamsState('state');
  const [fileName = '', setFileName] = useQueryParamsState('fileName');
  const [page] = usePageQueryParam([state, companyId, assigneeId, fileName]);
  const { data, isLoading } = useGetShipments(
    {
      ...createGraphQLCursorParams(page - 1, PER_PAGE_COUNT),
      byRepId: assigneeId === 'all' ? undefined : Number(assigneeId),
      companyId: companyId === 'all' ? undefined : companyId,
      id: !fileName ? undefined : fileName,
      state,
    },
    {
      select: data => ({
        shipments: data.shipments.nodes.map(shipment => ({
          ...shipment,
          company: {
            ...shipment?.company,
            name: shipment.company?.legalName,
          },
          status: calculateShipmentStatusColumnValue({
            state: shipment.state,
            progressStatus: shipment.progressStatus,
          }),
        })),
        totalPages: calculateTotalPagesFromGraphQL(
          data.shipments.totalCount,
          PER_PAGE_COUNT
        ),
      }),
    }
  );
  const { data: companies } = useCompanies(
    {
      businessType: 'CUSTOMER',
    },
    {
      select: data => [
        {
          label: t('filter-label-companies'),
          value: 'all',
        },
        ...data.map(({ id, legalName }) => ({ value: id, label: legalName })),
      ],
    }
  );
  const { data: users } = useUsersRequest(
    {
      temporaryInfinitePageSizeHack: true,
      roleSlug,
    },
    {
      select: data => [
        {
          label: t('filter-label-assignees'),
          value: 'all',
        },
        ...data.records.map(({ id, displayName, email }) => ({
          value: String(id),
          label: displayName || email,
        })),
      ],
    }
  );

  return {
    fileName,
    isLoading,
    page,
    setFileName,
    setState,
    shipments: data?.shipments ?? [],
    state,
    totalPages: data?.totalPages ?? 1,
    ...createFilters([
      {
        field: 'assigneeId',
        items: users,
        set: setAssigneeId,
        value: assigneeId,
      },
      {
        field: 'companyId',
        items: companies,
        set: setCompanyId,
        value: companyId,
      },
    ]),
  };
};

export const useShipmentsSidebar = () => {
  const {
    shipmentId: urlShipmentId,
    shipmentDetailsView: urlShipmentDetailsView,
    ...qp
  } = useQueryParams();
  const history = useHistory();
  const updateShipmentsSidebar = ({
    shipmentId = urlShipmentId,
    shipmentDetailsView = urlShipmentDetailsView,
    remove = false,
  }) => {
    let update = {};

    if (!remove) {
      update = {
        shipmentId,
        shipmentDetailsView,
      };
    }

    history.push({
      search: stringify(
        {
          ...qp,
          ...update,
        },
        { encode: false }
      ),
    });
  };

  return {
    shipmentId: urlShipmentId,
    shipmentDetailsView: urlShipmentDetailsView,
    updateShipmentsSidebar,
    qp,
  };
};
