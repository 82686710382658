import { useMemo } from 'react';
import {
  useUserRequest,
  useUpdateUser as useUpdateUserQuery,
} from 'core/api/usersRequest';

export const useUpdateUser = ({ userId, onSuccessCallback, t }) => {
  const { data: user, isLoading: isUserLoading } = useUserRequest(userId);
  const { isLoading: isSubmitting, mutate: updateUser } = useUpdateUserQuery(
    userId,
    onSuccessCallback
  );
  const { options, optionsHt } = useMemo(() => {
    const optionsHt = {
      cell: {
        value: 'cell',
        label: t('update-user-form-type-cell'),
      },
      direct: {
        value: 'direct',
        label: t('update-user-form-type-direct'),
      },
      nextel: {
        value: 'nextel',
        label: t('update-user-form-type-nextel'),
      },
      skype: {
        value: 'skype',
        label: t('update-user-form-type-skype'),
      },
      whatsapp: {
        value: 'whatsapp',
        label: t('update-user-form-type-whatsapp'),
      },
    };

    return {
      optionsHt,
      options: Object.values(optionsHt),
    };
  }, [t]);
  const initialValues = useMemo(
    () => ({
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumbers:
        user?.phoneNumbers?.map(n => ({
          ...n,
          type: optionsHt[n.type],
        })) || [],
    }),
    [user]
  );

  return {
    initialValues,
    isLoading: isUserLoading || isSubmitting,
    options,
    optionsHt,
    updateUser,
    user,
  };
};
