import { Grid, GridColumn, Heading } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { Stagger } from 'components/generic/kit';
import ShipmentForm from './ShipmentForm';

const CreateShipment = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <GridColumn
          startMd={5}
          md={4}
          sm={4}
          styles={{
            root: {
              margin: '48px 10px',
              minWidth: 0,
            },
          }}>
          <Stagger>
            <Stagger.Child>
              <Heading
                variant="h3"
                color="forest"
                style={{ marginBottom: '12px' }}>
                {t('create-new-shipment-heading')}
              </Heading>
            </Stagger.Child>

            <ShipmentForm />
          </Stagger>
        </GridColumn>
      </Grid>
    </>
  );
};

export default CreateShipment;
